import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import { api } from "./api";
import Logo from "./assets/topazLogo.png";
import LinearProgressWithLabel from "./components/progress-bar/progress-bar";

export const useStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      flexDirection: "column",
    },
    logo: {
      "& img": {
        width: "250px",
      },
      paddingTop: "20px",
      paddingBottom: "40px",
    },

    dropzone: {
      maxHeight: "500px",
      minHeight: "350px",
      backgroundColor: "rgb(61, 153, 245)",
      color: "#FEFBF3",
      "& .MuiDropzoneArea-icon": {
        color: "#FEFBF3",
      },
    },
    button: {
      "& .MuiButton-root": {
        padding: "15px",
        color: "#FFF",
        backgroundColor: "#e2241b",
        "&:hover": {
          color: "#FFF",
          backgroundColor: "#e2241b",
        },
      },
    },
  };
});

const App = () => {
  const [file, setFile] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<any>();
  const classes = useStyles();
  const [progress, setProgress] = React.useState<number>(0);
  const downloadManifest = async (url: string) => {
    setProgress(100);
    var link = document.createElement("a");
    link.download = "Topaz_Futbol_Proqramı.pdf ";
    link.target = "_blank";

    // Construct the URI
    link.href = url;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);
    setProgress(0);
  };

  React.useEffect(() => {
    if (loading) {
      let id = setInterval(() => {
        setProgress((value) => {
          return value < 90 ? value + 1 : 90;
        });
      }, 200);
      setIntervalId(id);
    }
  }, [loading]);

  React.useEffect(() => setProgress(0), [file]);
  React.useEffect(() => {
    if (progress === 100) {
      clearInterval(intervalId);
    }
  }, [progress]);

  const handleClick = async () => {
    const formBody = new FormData();
    formBody.append("file", file);
    try {
      setLoading(true);
      const axios = await api();
      const { data } = await axios.post("/upload", formBody, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 600000000,
      });
      setLoading(false);
      downloadManifest(data.pdfDownloadURL);
      clearInterval(intervalId);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      clearInterval(intervalId);
    }
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box className={classes.logo} mt={2}>
        <img alt="logo" src={Logo} style={{ filter: "invert(1)" }} />
      </Box>

      <DropzoneArea
        dropzoneText="Excell faylı seçin"
        filesLimit={1}
        onChange={(e) => setFile(e[0])}
        dropzoneClass={classes.dropzone}
        acceptedFiles={[
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ]}
        maxFileSize={5000000}
      />
      <Box className={classes.button} mt={3}>
        {loading ? (
          <LinearProgressWithLabel progress={progress} />
        ) : (
          <Button
            fullWidth
            onClick={handleClick}
            variant="contained"
            disabled={!file}
          >
            Pdf-ə çevir
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default App;
