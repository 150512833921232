import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return {
    container: {
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#3ed930",
      },
    },
  };
});

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          style={{ height: "8px" }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" style={{ color: "#FFF" }}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({
  progress,
}: {
  progress: number;
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
